import * as Sentry from '@sentry/browser'
const {
    isProduction,
    assetVersion,
    wsUserId,
    dsn
} = document.pageScope

const sentryConfig = {
    dsn,
    environment: isProduction ? 'production' : 'stage',
    release: assetVersion || '',
    tags: { wsUser: wsUserId },
    debug: !isProduction,
    denyUrls: [
        /\/instream\/video\/client\.js/i,
        // Chrome extensions
        /extension(s)?\//i,
        /^chrome:\/\//i
    ],
    ignoreErrors: [
        'ztePageScrollModule', // device/platform related error
        'document.getElementsByClassName.ToString', // seems to be a bug on Samsung Browser v7.2 https://github.com/SamsungInternet/support/issues/56
        '456', // status code for requests blocked by distil
        /SecurityError: DOM Exception 18$/,
        /Can't find variable: trk$/,  // partner tracking
    ]
}

Sentry.init(sentryConfig)
